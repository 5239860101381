var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-table-simple',{ref:"table",staticClass:"mail-table",staticStyle:{"min-height":"50vh"},attrs:{"sticky-header":"","striped":"","hover":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th'),_c('b-th',[_vm._v("Email Id")]),_c('b-th',[_vm._v("Received date")]),_c('b-th',[_vm._v("From")]),_c('b-th',[_vm._v("Subject")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Pos Id ")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Ticket Id ")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Create new subticket ? ")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Contract Id ")]),_c('b-th',[_vm._v("File Name")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" DocumentType ")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Board ")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Status ")]),_c('b-th',{staticClass:"text-center"},[_vm._v(" Action ")])],1)],1),_vm._l((_vm.items),function(item,idx){return [_c('b-tbody',{key:idx},[_c('mail-tr',{key:("email-" + (item.email_id)),attrs:{"table-el":_vm.$refs.table.$el,"item":item},on:{"show-content":function($event){return _vm.showMailContent(item)},"classify":function (ref) {
	var $vm = ref.$vm;
	var shouldCreateSubTicket = ref.shouldCreateSubTicket;

	return _vm.classifyNew(item, $vm, 'classify', { shouldCreateSubTicket: shouldCreateSubTicket });
},"reject":function($event){return _vm.classifyNew(item, _vm.$vm, 'dismiss')}}})],1),_c('transition',{key:'c'+idx,attrs:{"name":"slide"}},[(item.documents.length>0)?_c('b-tbody',{directives:[{name:"show",rawName:"v-show",value:(item.open),expression:"item.open"}],attrs:{"id":'collapse'+item.email_id}},_vm._l((item.documents),function(child,idx){return _c('mail-tr',{key:((child.document_id) + "-idx"),staticStyle:{"background-color":"white !important"},attrs:{"table-el":_vm.$refs.table.$el,"item":child,"child":""},on:{"classify":function (ref, options) {
	var $vm = ref.$vm;
	var shouldCreateSubTicket = ref.shouldCreateSubTicket;

	return _vm.classifyNew(child, $vm, 'classify', {parentEmail: item, shouldCreateSubTicket: shouldCreateSubTicket });
},"reject":function($event){return _vm.classifyNew(child, _vm.$vm, 'dismiss', {parentEmail: item})}}})}),1):_vm._e()],1)]}),(_vm.items.length===0)?_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"14"}},[_vm._v(" No Data available ")])],1)],1):_vm._e()],2),_c('b-modal',{ref:"mailContent",attrs:{"title":"Mail Content","size":"lg","ok-title":"Close","ok-only":""}},[(_vm.item)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('field',{attrs:{"field":{key: 'email_id'},"entity":_vm.item,"disabled":""}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('field',{attrs:{"field":{key: 'email_received_datetime'},"entity":_vm.item,"disabled":""}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('field',{attrs:{"field":{key: 'email_from'},"entity":_vm.item,"disabled":""}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('field',{attrs:{"field":{key: 'email_to'},"entity":_vm.item,"disabled":""}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('field',{attrs:{"field":{key: 'email_body', type: 'html'},"entity":_vm.item,"disabled":""}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }